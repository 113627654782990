import { createApp } from 'vue'
import App from './App.vue'
import router from './_router'
import store from './_store/store'
import AppLayout from './components/AppLayout.vue'
import VueAnimXyz from '@animxyz/vue3'
import '@animxyz/core'
import './assets/tailwind.css'

const app = createApp(App)
  .use(store)
  .use(router)
  .component('AppLayout', AppLayout)
  .mount('#app')

app.use(VueAnimXyz)
