import { createRouter, createWebHistory } from 'vue-router'
// import Home from '../views/Home.vue'
import Land from '../landing/Land.vue'
// import ComingSoon from '../landing/ComingSoon.vue'
import Guide from '../views/Guide.vue'
const routes = [
  {
    path: '/',
    name: 'Land',
    component: Land
  },
  {
    path: '/guide',
    name: 'Guide',
    component: Guide
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import('../views/FAQ.vue')
  },
  {
    path: '/glossary',
    name: 'Glossary',
    component: () => import('../views/Glossary.vue')
  },
  {
    path: '/anticipation',
    name: 'AnticipatePage',
    component: () => import('../views/AnticipatePage.vue')
  },
  {
    path: '/blockchains',
    name: 'Blockchains',
    component: () => import('../views/Blockchains.vue')
  },
  {
    path: '/explorer',
    name: 'ChainExplorerDemo',
    component: () => import('../views/ChainExplorerDemo.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
