<template>
  <div id="Hero">
    <img src="@/assets/gfx/blockchain.svg" class="gfx gfx-1" />
    <img src="@/assets/gfx/goingup.svg" class="gfx gfx-2" />
    <div class="hero-content">
      <h1 class="font-bold">DECENTRALIZED STOCK EXCHANGE</h1>
      <p>Bringing traditional finance into digital assets space</p>
      <br />
      <router-link class="primary-btn2" :to="{name:''}">Coming Soon</router-link>
    </div>
</div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Hero',
  components: {

  },
  data(){
    return{
      placeholderImg: 'https://images.unsplash.com/flagged/photo-1579225818168-858da8667fae?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
    }
  }
}
</script>
<style scoped>
#Hero{
  position: relative;
  width:100%;
  color: #fff;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.gfx{
  position: absolute;
  opacity: .06;
    width: 50%;
}
.gfx-1{
  transform: rotate(25deg);
  top: 0;
  right: -15%;

}
.gfx-2{
  transform: rotate(-45deg);
  top: 0;
  left: -15%;
}
.hero-content{
  width: 80%;
  margin: 3rem auto;
}
.hero-content h1{
  font-size: 3rem;
}
a{
  text-decoration: none;
}
.fullscreen-img-wrap {
  width: 100%;
  height: 80vh;
  overflow:hidden;
  position: relative;
  /* Set a specific height */
  min-height: 500px;
  background-attachment: fixed;
  background-position:center;
  background-repeat: no-repeat;
  background-size: cover;
}
.overlay{
  overflow-x: hidden;
  max-width: 100vw;
  position: absolute;
  width: 100%;
  height: 80vh;
  top:0;
  background: #111;
  opacity: 0.6;
  z-index: 1;
  display: flex;
  justiy-content:center;
  align-item: center;
}
.over-content{
  z-index: 2;
  top: 34%;
  width: 100%;
  position: absolute;
  color:#fff;
  font-size: 20px;
  font-weight: bold;
  color:#fff;
  text-align: center;
}
.over-content h1{
  font-size: 2.9rem;
  text-transform: uppercase;
}
.h2{
  width: 80%;
}
.over-content p{
  color: #fff;
  font-weight: 400;
  text-transform: uppercase;
  -webkit-font-family: Bahnschrift;
  font-family: Bahnschrift;

}
.over-content label{
  color: #fff;
  font-size: 30px;
  font-weight: 100;
  -webkit-font-family: Arial, Helvetica, sans-serif;
  font-family: Arial, Helvetica, sans-serif;
}
.over-content-title{
  -webkit-font-family: Arial, Helvetica, sans-serif;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 3.99rem;
  position: relative;
}
.main-btn{
  background-color: #fff;
  padding: .6rem 2rem;
  color: #464646;
  border:none;
}
.primary-btn{
  font-size: 1.2rem;
  text-transform: uppercase;
}
.before-enter{
  opacity: 0;
  transform: translateY(100px);
  transition: all 2s ease-out;
}
.enter{
  opacity: 1;
  transform: translateY(0px);
}
.main-brand{
  width: 60%;
  margin: 0 auto;
  margin-bottom: 3rem;
}
.primary-btn2{
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items:center;
  background-color: #000;
  padding: 1rem 2rem;
  height:100%;
  max-width: 20%;
  margin: 0 auto;
  color: #fff;
  border-radius: 10px;
  transition: 1s;
  box-shadow: 0 0 0 0 rgba(#222, .5);
  -webkit-box-shadow: 0 0 0 0 rgba(#222, .5);
  -webkit-animation: pulse 1.5s infinite;
  animation: pulse 2s infinite;
}
.primary-btn2:hover{
  -webkit-animation: none;
  animation: none;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

</style>
