<template>

<footer class="bg-gray-900 text-center text-white">
  <div class="pt-6">
    <div class="flex justify-center item-center mb-6 mx-auto">

      <a class="link-to" target="_blank" :href="link.route" v-for="(link, idx) in links" :key="idx">
        <!-- <img class=" mx-auto" :src="link.icon" /> -->
      </a>

    </div>

    <div>
      <form action="">
        <div class="flex justify-center items-center">
          <div class="md:ml-auto md:mb-6">
            <p class="">

            </p>
          </div>

          <div class="md:mb-6">
            <input
              type="text"
              class=" bg-gray-500 form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700  bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:border-blue-600 focus:outline-none"
              id="exampleFormControlInput1"
              placeholder="Email address"/>
          </div>

          <div class="md:mr-auto mb-6">
            <button type="submit" class="inline-block px-6 py-2 border-2 border-white text-white font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
            @click.prevent="subscribeToMailingList()">
              Subscribe
            </button>
          </div>
        </div>
      </form>
    </div>

    <div class="mb-6">
      <p>
        Sign up to be the first recieve information regarding Tulip Decentralized Stock Exchange
      </p>
    </div>

    <!-- <div class="grid lg:grid-cols-4 md:grid-cols-2">
      <div class="mb-6">
        <h5 class="uppercase font-bold mb-2.5">Links</h5>

        <ul class="list-none mb-0">
          <li>
            <a href="#!" class="text-white">Link 1</a>
          </li>
          <li>
            <a href="#!" class="text-white">Link 2</a>
          </li>
          <li>
            <a href="#!" class="text-white">Link 3</a>
          </li>
          <li>
            <a href="#!" class="text-white">Link 4</a>
          </li>
        </ul>
      </div>
    </div> -->

  </div>

  <div class="text-center p-4" style="background-color: rgba(0, 0, 0, 0.2);">
    © 2022 Copyright:
    <a class="text-white" href="https://tailwind-elements.com/">TulipDex</a>
  </div>
</footer>

</template>
<script>

import axios from 'axios'

export default {
  name: 'Land',
  components: {

  },
  data(){
    return{
      email: '',
      feedback:'',
      feedtype:'',
      processing: false,
      links:[
        {
        name:'Twitter',
        // icon: require('@/assets/icons/social-media/twitter.svg'),
        route: 'https://twitter.com/tycooperaow'},
        {
        name:'Telegram/Contact',
        // icon: require('@/assets/icons/social-media/telegram.svg'),
        route: 'https://t.me/tycooperaow'}
      ],
      nav_menu:[
        {name:'Twitter', route: 'https://twitter.com/tycooperaow'},
        {name:'Home', route: '/home'},
        {name:'About', route: '/about'},
        {name:'Telegram', route: 'https://t.me/tycooperaow'}
      ],
      myInfo: [
        {name:'Research', route: 'https://twitter.com/tycooperaow'},
      ]
    }
  },
  computed:{
    // ...mapGetters([
    //   'isAuthenthicated'
    // ]),
  },
  methods:{
    goto(route){
      if(route != ''){
        window.open(route)
      }
    },
    subscribeToMailingList(){
      if(!this.email){
        this.feedback= "You must enter a valid email!"
        this.feedtype= "danger"
      }else{
        let payload = {
          email: 'tulip|'+this.email.toLowerCase()
        }
        if(this.email.includes('@') && this.email.includes('.')){
          axios.post('https://api.skylineexecutive.com/newsletter-crypto', payload).then((res)=>{
              this.feedback=res.data.feedback
              this.feedtype=res.data.feedtype
              // alert(res.data.dull_list)
          }).catch((err) => {
            this.error = err
          })
        }else{
          this.feedback = "You must enter a valid email!"
          this.feedtype= "danger"
        }
      }
    },
  }
}
</script>
<style scoped>
#Footer{
  width: 100%;
  height: 10rem;
  padding-bottom: 5rem;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items:center;
  transition: 1s;
  background-color: #000;

}
a{
  text-decoration: none;
}
.link-to img{
  width: 2rem;
  margin: 0 1rem;
}
.left-side{
  display: flex;
  justify-content: flex-start;
  align-items:center;
  color: #fff;
  width: 90%;
  margin: auto;
  margin-top: 5rem;
}
.left-side p{margin-left: 3rem;}
.brand-logo{
  width: 3rem;
}
.right-side{
  width: 50%;
  display: flex;
}
.links {
  list-style-type: none;
  margin: 0 2rem;
  padding: 0;
  overflow: hidden;
  transition: 1s;
  color: #fff;
}
</style>
