<template>
<div id="TranslationArea">
  <button>Translate to</button>
</div>
</template>
<script>
// import {mapState, mapGetters} from 'vuex'

export default {
  name: 'TranslationArea',
  components: {

  },
  data(){
    return{
    }
  },
  computed:{
    // ...mapGetters([
    //   'isAuthenthicated'
    // ]),
  },
  methods:{
    goto(route){
      if(route != ''){
        window.open(route)
      }
    }
  }
}
</script>
<style scoped>
</style>
