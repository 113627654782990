<template>
  <div id="Land">
    <!-- <NavBar /> -->
    <Hero />
    <FeaturedChains />
    <WhyTulip />
    <!-- <Roadmap /> -->
    <TheTeam />
  </div>
</template>

<script>
// import NavBar from '@/landing/components/NavBar.vue'
import Hero from '@/landing/components/Hero.vue'
import FeaturedChains from '@/landing/components/FeaturedChains.vue'
import WhyTulip from '@/landing/components/WhyTulip.vue'
// import Roadmap from '@/landing/components/Roadmap.vue'
import TheTeam from '@/landing/components/TheTeam.vue'

export default {
  name: 'Land',
  components: {
    // NavBar,
    Hero,
    FeaturedChains,
    WhyTulip,
    // Roadmap,
    TheTeam,
  },
  data(){
    return{
    }
  },
  methods:{
  },
}
</script>
<style scoped>
#Land{
  width: 100%;
  height: 100%;
  min-height: 120vh;
}
</style>
