<template>
  <div id="WhyTulip">
    <div class="how-it-works-img">
      <img id="tulip-media" src="https://firebasestorage.googleapis.com/v0/b/tulipdex.appspot.com/o/site_assets%2F6-3D.jpeg?alt=media&token=1cc0a6d7-a8d6-4719-aae0-0bc6b8a10480" />

    </div>
    <div class="how-it-works-text">
      <p class="title">WHY TULIP?</p>
        <p>Tulip is a project seeking to build a decentralized stock exchange utilizing web web3 technologies. Tulip emerged during the $GME and $AMC meme stock short squeeze event that had occured in Q1 of 2021. During the height of the event, many retail traders and investors were pushed off the platform.</p>

    </div>
  </div>
</template>
<script>

export default {
  components: {
  },
  name: 'WhyTulip',
  data(){
    return{
      window:{},
      isMobile: false,
    }
  },
  methods:{

  },
}
</script>
<style scoped>
#WhyTulip{
  width: 90%;
  margin: 8rem auto;
  text-align: center;
  transition: 1s;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 1px;
  grid-row-gap: 2px;
  justify-items: stretch;
  align-items: stretch;
}
.how-it-works-text h1{
  font-size: 2rem;
  margin: 2rem 0;
}
.how-it-works-text p{
  font-size: .9rem;
}
.how-it-works-text .title{
  color: #fff;
  margin-bottom: 1rem;
  font-size: 1.1rem;
  opacity: .7;
}
.how-it-works-img{
  display: flex;
  justify-items: center;
  align-items: center;
}
#tulip-media{
  width: 80%;
  border-radius: 5px;
  border: 4px solid #fff;
}
.img-pic{
  min-width: 20rem;
  width: 80%;
  transition: 1s;
}
.how-it-works-text{
  width: 90%;
  text-align: left;
  font-weight: bold;
  color: #fff;
}
.link{
  text-decoration: none;
  color: #fff !important;
}
@media only screen and (max-width: 600px) {
  #WhyTulip{
    width: 90%;
    margin: 4rem auto;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
  .how-it-works-text h1{
    font-size: 1.2rem;
    margin: 2rem 0;
  }
  .how-it-works-text p{
    font-size: .8rem;
    margin: 2rem 0;
  }
}
@media only screen and (max-width: 400px) {
  .how-it-works-text p{
    font-size: .6rem;
    margin: 1rem 0;
  }
}
</style>
