<template>
<div id="Header">
  <div class="left-side">
    <router-link :to="{name: 'Land'}" class="brand-link flex items-center justify-center">
      <img src="@/assets/logo.png" class="brand-logo"/>
      <span class="logo-text">TULIP DEX</span></router-link>
  </div>
  <div class="mid-side">
    <ul class="links">
      <li v-for="(nm, idx) in nav_menu" :key="idx" @click="goto(nm.route, nm.external_link)">
        <p>{{nm.name}}</p>
      </li>
    </ul>
  </div>
  <div class="right-side">
    <span >
        <router-link :to="{name:'Land'}" class=" primary-btn2">
          <div class="gradient-text"  >Coming Soon</div>
        </router-link>
    </span>
  </div>
</div>
</template>
<script>
// import {mapState, mapGetters} from 'vuex'

export default {
  name: 'Header',
  components: {

  },
  data(){
    return{
      nav_menu:[
        // {name:'*Guide*', route: 'Guide'},
        {name:'Discord', route: 'https://discord.gg/2AYYwrDFy7', external_link: true},
        {name:'Twitter', route: 'https://twitter.com/TulipDex', external_link: true},
        {name:'About Us', route: '/about', external_link: false},
        {name:'Reddit', route: 'https://www.reddit.com/r/TulipDEX/', external_link: true},
        {name:'Litepaper', route: 'https://drive.google.com/file/d/11l3fz0tDqRyVH_hkEX9cTtOwWG1ks-_T/view?usp=sharing', external_link: true},
        {name:'Telegram/Contact', route: 'https://t.me/tulipdex', external_link: true}
      ]
    }
  },
  computed:{
    // ...mapGetters([
    //   'isAuthenthicated'
    // ]),
  },
  methods:{
    goto(route, external){
      if(route != ''){
        if(!external){
          window.open(route, '_self')
        }else{
          window.open(route)
        }
      }
    }
  }
}
</script>
<style scoped>
#Header{
  width: 95%;
  height: 6rem;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items:center;
  transition: 1s;
}
a{
  text-decoration: none;
}
.left-side{
  display: flex;
  justify-content: flex-start;
  align-items:center;
}
.brand-logo{
  width: 3rem;
}
.links {
  list-style-type: none;
  margin: 0 2rem;
  padding: 0;
  overflow: hidden;
  transition: 1s;
  color: #fff;
}
.right-side{

}
.logo-text{
  color: #fff;
  font-weight: bold;
  font-size: 1.5rem;
  margin-left: .5rem;
}
.brand-logo{
  width: 3rem;
}
.right-side-btn-group{
  display: flex;
  justify-content: space-evenly;
  align-items:center
}
.primary-btn2{
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items:center;
  background-color: #000;
  padding: 1rem 2rem;
  height:100%;
  margin:auto .1rem;
  border-radius: 10px;
  transition: 1s;
  box-shadow: 0 0 0 0 rgba(#222, .5);
  -webkit-box-shadow: 0 0 0 0 rgba(#222, .5);
  -webkit-animation: pulse 1.5s infinite;
  animation: pulse 2s infinite;
}
.primary-btn2:hover{
  -webkit-animation: none;
  animation: none;
}
.gradient-text {
  background-color: #fff;
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}
li {
  float: left;
}
li:hover {opacity: .7}
li p {
  display: block;
  color: #fff;
  cursor:pointer;
  text-align: center;
  padding: 16px;
  text-decoration: none;
}

li a:hover {
  opacity: .7;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}


@media only screen and (max-width: 600px) {
li p {
    padding: 5px;
}
li {
  font-size: 10px;
}
.btn{
  display: flex;
  justify-content: center;
  font-size: .5em;
  padding: .7rem 1rem ;
  margin: .3rem;
  text-align: center;
  width: 4rem;
}
.right-side-btn-group{

}
}
@media only screen and (max-width: 400px) {
  li{
    font-size: 4px;
  }
  .primary-btn2{
    font-size: 6px;
  }
}
</style>
