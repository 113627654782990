<template>
  <p class="chain-announcement">Expected to be supported on the following blockchains</p>
  <div id="FeaturedChains">
    <p class="chain" v-for="(chain, idx) in upcomingchains" :key="idx">{{chain}}</p>
  </div>
  <!-- <router-link>Read More</router-link> -->
</template>

<script>

export default {
  name: 'FeaturedChains',
  components: {
  },
  data(){
    return{
      upcomingchains: ['Ethereum', 'BSC', 'Solana', 'Polkadot', 'Polygon', 'Cardano', 'xDai', 'Avalanche']
    }
  }
}
</script>
<style scoped>
.chain-announcement{
  color: #fff;
  margin: 2rem auto;
}
#FeaturedChains{
  width: 100%;
  display: flex;
  justify-content: space-evenly;;
  align-items: center;
  transition: 1s;
}
.chain{
  color: #fff;
  font-size: 15px;
  transition: 1s;
  font-weight: bold;
  cursor:pointer;
}
.chain:hover{
  transform: translateY(-10px);
}
/* #FeaturedChains h1{
  font-size: 3rem;
}
#FeaturedChains p{
  font-size: 1.1rem;
  font-weight: bold;
  margin: 2rem auto;
  text-align:center;
  color: #000;
  width: 70%;
}
.features{
  width: 100%;
  max-width: 6rem;
  min-height: 4rem;
  overflow:hidden;
  position: relative;
  background-position:center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: 1s;
}
.primary-btn{
  font-size: 1.2rem;
  text-decoration: none;
  text-transform: uppercase;
}
.map-icon{
  width: 15%;
} */
@media only screen and (max-width: 600px) {
  #FeaturedChains{
    flex-wrap: wrap;
  }
  .chain{
    font-size: .8rem;
  }
}

</style>
