<template>
  <div class="the-team">
    <div class="main-section">
      <h1>The Team</h1>
      <div class="team">
        <div class="member" v-for="(t, idx) in team" :key="idx">
          <img src="@/assets/placeholder.svg"/>
          <p class="text-yellow-200">Tulip {{t}}</p>
        </div>
      </div>
  </div>
</div>
</template>
<script>
export default {
  name: 'Land',
  components: {
  },
  data(){
    return{
      team:[
        'Lstyle',
        'Ty',
        'Gil',
        'Will_ii',
      ]
    }
  },
  methods:{
  },
}
</script>
<style scoped>
.about{
  width: 100%;
  height: 100%;
  min-height: 100vh;
  color: #fff;
}
.main-section{
  width: 80%;
  margin: 3rem auto;
}
.main-section h1{
  font-size: 3rem;
  color: #fff;
  margin-bottom: 2rem;
}
.content-text{
  text-align: left;
}
.content-text p{
  margin: 1rem .2rem;
  margin-left: 2rem;
}
.member img{
  width: 8rem;
}
.member p{
  font-weight: bold;
  font-size: 1.5rem;
}
.team{
  display: flex;
  width: 80%;
  margin: auto;
  align-items:center;
  justify-content: space-evenly;
}
</style>
